const themeSettingsDirectTop2 = {
  menu: {
    logoimage: {
      url: null,
      width: null,
      height: null,
      focalpoint: {
        x: 50,
        y: 50,
      },
    },
    show_logo: true,
    title: null,
    menu_icon: true,
    menu_index: true,
    article: false,
    pagination: false,
    shadow: true,
    show_borders: false,
    background_color: '#ffffff',
    color: '#202020',
    scrollMenu: false,
    menuTags: false,
    hideNavOnScroll: false,
    hideNavScrollOnlyOnMobile: false,
  },
  sideMenu: {
    menu_icon: true,
    menu_index: true,
  },
  menuButtons: {
    mobile_dropdown: true,

    activate_search: false,
    search_tooltip: null,
    search_showOnMobile: false,

    activate_cookies: false,
    cookies_tooltip: null,
    cookies_showOnMobile: false,

    activate_sharing: false,
    sharing_tooltip: null,
    sharing_showOnMobile: false,

    activate_intro: false,
    intro_tooltip: null,
    intro_showOnMobile: false,

    activate_facebook: false,
    facebook: null,
    facebook_tooltip: null,
    facebook_showOnMobile: false,

    activate_instagram: false,
    instagram: null,
    instagram_tooltip: null,
    instagram_showOnMobile: false,

    activate_linkedin: false,
    linkedin: null,
    linkedin_tooltip: null,
    linkedin_showOnMobile: false,

    activate_twitter: false,
    twitter: null,
    twitter_tooltip: null,
    twitter_showOnMobile: false,

    activate_tiktok: false,
    tiktok: null,
    tiktok_tooltip: null,
    tiktok_showOnMobile: false,

    activate_xing: false,
    xing: null,
    xing_tooltip: null,
    xing_showOnMobile: false,

    activate_youtube: false,
    youtube: null,
    youtube_tooltip: null,
    youtube_showOnMobile: false,

    activate_website: false,
    website: null,
    website_tooltip: null,
    website_showOnMobile: false,

    activate_print: false,
    print: null,
    print_tooltip: null,
    print_showOnMobile: false,

    activate_overview: false,
    overview: null,
    overview_tooltip: null,
    overview_showOnMobile: false,

    activate_imprint: false,
    imprint: null,
    imprint_tooltip: null,
    imprint_showOnMobile: false,

    activate_dataprivacy: false,
    dataprivacy: null,
    dataprivacy_tooltip: null,
    dataprivacy_showOnMobile: false,

    activate_custom1: false,
    custom1: null,
    custom1_tooltip: null,
    custom1_icon: 'fa-heart',
    custom1_showOnMobile: false,
    custom1_openInNewTab: false,
    activate_custom2: false,
    custom2: null,
    custom2_tooltip: null,
    custom2_icon: 'fa-heart',
    custom2_showOnMobile: false,
    custom2_openInNewTab: false,

    custom_button_1: {
      activate: false,
      showOnMobile: false,
      openInNewTab: false,
      url: null,
      text: null,
    },
    custom_button_2: {
      activate: false,
      showOnMobile: false,
      openInNewTab: false,
      url: null,
      text: null,
    },
  },
  articleSettings: {
    activate_width: false,
    maxWidth: null,
    maxWidthBackgroundColor: null,
  },
  navigationArrow: {
    navigation: true,
    navigation_hide: false,
    navigation_position: false,
    showNavigationOnMobile: true,
    showNavigationAtSides: false,
  },
  navigationWidget: {
    activate_pagination: false, // here it is called pagination
    showPaginationDesktop: false,
    hidePaginationOnScroll: false,
  },
  bottomNavigationBar: {
    showBottomNavigationBar: false,
    showOnDesktop: false,
    hideOnScroll: false,
    topValueOnDesktop: 100,
    data: [],
  },
};

export default themeSettingsDirectTop2;
